<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-row class="text-center">
          <b-col>
            <b-img
              :src="logo"
              style=""
              height="80"
            />
          </b-col>
        </b-row>
        <b-row class="text-center">
          <b-col>
          </b-col>
        </b-row>
        <!-- form -->
        <validation-observer
          ref="loginValidation"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  type="email"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="exemplo@exemplo.com.br"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label-for="password"
              label="Senha"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Senha"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div class="d-flex justify-content-between">
                <b-link :to="{name:'perdi-minha-senha'}">
                  <small>Esqueceu a sua senha?</small>
                </b-link>
              </div>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="validationForm"
            >
              Acessar
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      logo: require('@/assets/images/logo/logo.png'),
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    if (localStorage.getItem('userData')) {
      this.$router.push({ name: 'home' })
    }
  },
  methods: {
    ...mapActions('auth', ['me']),
    async validationForm() {
      this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          const authUser = await this.$http.post('auth/login', { email: this.userEmail, password: this.password })
          if (!authUser || authUser.error) {
            localStorage.clear()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Usuário ou senha inválidos',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
            return false
          }
          localStorage.setItem('accessToken', authUser.access_token)
          const me = await this.me()
          localStorage.setItem('userData', JSON.stringify(me))
          this.$router.push({ name: 'home' })
        }
        return true
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
